import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faAddressCard,
  faChartPie,
  faCogs,
  faDoorOpen
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import { useAuth } from '../store/features/auth/authSlice';
import type {UserInfo} from '../store/features/auth/authTypes';

interface NavItemProps {
  navItem: {
    label: string;
    path: string;
    icon: any; // FontAwesomeIconProps can be used if available
    allowedRoles: Array<'user' | 'admin'>;
  };
}

const navItems: NavItemProps['navItem'][] = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: faChartLine,
    allowedRoles: ['user', 'admin']
  },
  {
    label: 'Inventory',
    path: 'inventory',
    icon: faChartPie,
    allowedRoles: ['admin']
  },
  {
    label: 'Monthly',
    path: 'iBMiVsICL',
    icon: faAddressCard,
    allowedRoles: ['user', 'admin']
  },
  {
    label: 'Settings',
    path: 'settings',
    icon: faCogs,
    allowedRoles: ['user', 'admin']
  },
  {
    label: 'Users',
    path: 'users',
    icon: faDoorOpen,
    allowedRoles: ['admin']
  }
];

const NavItem: React.FC<NavItemProps> = ({ navItem }) => {
  const location = useLocation();
  const isCurrentRoute = location.pathname === `/${navItem.path}`;
  const classes = classNames({
    'px-2 sm:px-6 justify-center sm:justify-start py-3 rounded-full flex': true,
    'text-gray-600 hover:text-blue-500 transform hover:translate-x-1 transition ease-in-out duration-100': !isCurrentRoute,
    'bg-gradient text-gray-100 shadow-lg': isCurrentRoute
  });

  return (
    <Link to={`/${navItem.path}`} className={classes}>
      <div className="flex items-center">
        <div className="mr-0 sm:mr-4">
          <FontAwesomeIcon icon={navItem.icon} />
        </div>
        <span className="hidden sm:block">
          {navItem.label}
        </span>
      </div>
    </Link>
  );
};

const NavItemContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>{children}</div>
);

const Sidebar: React.FC = () => {
  const {userInfo} = useAuth();
  const role = (userInfo as UserInfo).role;

  return (
    <section className="h-screen flex flex-col">
      <div className="w-full px-0 pb-4 flex justify-center">
        <div className="text-xl sm:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-teal-500">
          APS Benicia
        </div>
      </div>
      <div className="flex-1 mt-10 overflow-auto">
        {navItems.map((navItem, i) => (
          <NavItemContainer key={i}>
            {navItem.allowedRoles.includes(role) && (
              <NavItem navItem={navItem} />
            )}
          </NavItemContainer>
        ))}
      </div>
    </section>
  );
};

export default Sidebar;

import { MiddlewareAPI } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../../index.js';
import {getSocket, disconnectSocket} from '../../../utils/wsClient';
import {wsActions} from '../../features/ws/wsSlice';

import {wsIncomingMessages, wsIncomingMessagesKeys} from '../../../utils/wsClient/wsMessages';


const wsActionConnect = (store: MiddlewareAPI<AppDispatch, RootState>):void => {

    const socket = getSocket(store.getState().auth.token as string);


    socket.on('connect_error', (error: Error) => {
        console.error('WebSocket connect_error:', error);
    });  // Handling connection specific errors
    socket.on('connect_timeout', (error: Error) => {
        console.error('WebSocket connect_timeout:', error);
    });

    socket.on('connect', () => {
        // socket.recovered is set to true when the socket is reconnected and the 'session' is restored
        if (!socket.recovered) {
            // if the socket is not recovered, it means it's a new or unrecoverable session
            // => we need to reset the state in this case

            // then, we need to register the device
            // and if we have a valid token, we need to login as well
        }

        if (socket.id) {
            store.dispatch(wsActions.connected({socketId: socket.id}));
        } else {
            store.dispatch(
                wsActions.connectionError("Socket connected but didn't receive an ID."),
            );
        }
    });

    socket.on('disconnect', reason => {
        if (
            reason === 'io server disconnect' ||
            reason === 'io client disconnect'
        ) {
            // the disconnection was initiated by the server or client,
            // we need to reconnect manually if we want to
            store.dispatch(wsActions.disconnected());
            socket.offAny();
            disconnectSocket();
        } else {
            // else the socket will automatically try to reconnect
            store.dispatch(wsActions.reconnecting());
        }
    });

    socket.on('error', (error: Error) => {
        console.error('WebSocket error:', error);
    });

    socket.onAny((messageName: wsIncomingMessagesKeys, data: any) => {
        if (wsIncomingMessages[messageName]) {
            wsIncomingMessages[messageName](data);
        }
    });

    socket.connect();
};

export default wsActionConnect;

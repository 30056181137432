// src/features/userSessionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {WsState, WsStatusEnum} from './wsTypes';


const initialState: WsState = {
    status: WsStatusEnum.idle,
    socketId: null,
    lastError: null,
};

export const wsSlice = createSlice({
    name: 'ws',
    initialState,
    reducers: {
        connect: (state) => {
            state.status = WsStatusEnum.connecting;
        },
        connected: (state, action: PayloadAction<{socketId: string}>) => {
            state.status = WsStatusEnum.connected;
            state.socketId = action.payload.socketId;
        },
        disconnected: (state) => {
            state.status = WsStatusEnum.disconnected;
            state.socketId = null;
        },
        reconnecting: (state) => {
            state.status = WsStatusEnum.reconnecting;
            state.socketId = null;
        },
        connectionError: (state, action: PayloadAction<string>) => {
            state.status = WsStatusEnum.error;
            state.lastError = action.payload;
        }
    },
});

export const useWsConnect = () => {
    const dispatch = useAppDispatch();
    const connectWs = () => {
        dispatch(wsSlice.actions.connect());
    };

    const wsStatus = useAppSelector((state) => state.ws.status);

    return {connectWs, wsStatus};
};

export const wsActions = wsSlice.actions;

export default wsSlice.reducer;

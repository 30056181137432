import {io, Socket} from 'socket.io-client';

export let socket: Socket | null = null;
export const getSocket = (token?: string): Socket => {
    if (!socket) {
        if (!token) {
            throw new Error('getSocket: No token provided.');
        }
        socket = io(
            {
                query: { token },
                autoConnect: false,
            }
        );
    }
    return socket as Socket;
};

export const disconnectSocket = () => {
    if (!socket) {
        return;
    }
    socket.disconnect();
    socket = null;
}

import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import { useAuth, useTokenValidation } from "./store/features/auth/authSlice";
import { useWsConnect } from './store/features/ws/wsSlice';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import AppShell from "./AppShell";

const Home = lazy(() => import("./pages/Home"));
const FourOFour = lazy(() => import('./pages/FourOFour'));
const IbmiIcl = lazy(() => import("./pages/IbmiIcl"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));

// const ErrorPage = lazy(() => import('./pages/ErrorPage'));

interface RequireAuthProps {
  children: React.ReactNode;
  // allowedRoles: string[];
}

interface RedirectIfAuthenticatedProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    console.log("Redirecting to login...");
    // Redirect unauthenticated users to the login page
    return <Navigate to="/login" replace />;
  }
  // else if (allowedRoles && !allowedRoles.includes(userRole)) {
  //     // Redirect unauthorized users to an error page or home depending on your design
  //     return <Navigate to="/unauthorized" replace />;
  // }
  return <AppShell>{children}</AppShell>;
};

const RedirectIfAuthenticated: React.FC<RedirectIfAuthenticatedProps> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    console.log("Redirecting to home...");
    // Redirect authenticated users to the home page
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const routes = createBrowserRouter([
  {
    path: "/login",
    element: (
      <RedirectIfAuthenticated>
        <Login />
      </RedirectIfAuthenticated>
    ),
  },
  {
    path: "/signup",
    element: (
      <RedirectIfAuthenticated>
        <Signup />
      </RedirectIfAuthenticated>
    ),
  },
  {
    path: "*",
    element: (
        <FourOFour />
    ),
  },
  {
    path: "/",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: "/iBMiVsICL",
    element: (
      <RequireAuth>
        <IbmiIcl />
      </RequireAuth>
    ),
  },
]);

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const {connectWs, wsStatus} = useWsConnect();
  
  useTokenValidation();

  useEffect(() => {
    if (isAuthenticated) {
      if (wsStatus === 'idle' || wsStatus === 'disconnected') {
        connectWs();
      }
    } else {
      if (wsStatus === 'connected') {
      // disconnectWs();
      }
    }
  }, [isAuthenticated, wsStatus, connectWs]);

  return (
    // <div className="bg-gray-100">
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={routes} />
      </Suspense>
    // </div>
  );
};

export default App;

import {store} from '../../store';
import {authActions} from '../../store/features/auth/authSlice';
import {AuthPayload} from "../../store/features/auth/authTypes";
import {monthlyActions} from '../../store/features/monthly/monthlySlice';
import type {ProcessingStatus} from '../../store/features/monthly/monthlyTypes';

interface ReconciliationData {
    fileName: string;
}

const apiUrl = process.env.REACT_APP_API_URL;
if (!apiUrl) {
  throw new Error("REACT_APP_API_URL is not set.");
}

export const wsIncomingMessages = {
    auth: (data: AuthPayload): void => {
        console.log('auth', data);
        store.dispatch(authActions.setToken(data));
    },

    invalid_token: (): void => {
        console.error('Invalid token');
        store.dispatch(authActions.logout());
    },
    
    // monthly_ibmiicl_fileReceived: (data: PartialFileUploaded): void => {
    //     console.log('monthly_ibmiicl_fileReceived', data);
    //     store.dispatch(monthlyActions.setUploadedFile(data));
    // },

    monthly_ibmiicl_reconciliationStatus: (data: ProcessingStatus): void => {
        store.dispatch(monthlyActions.setStatus(data));
    },

    monthly_ibmiicl_discripanciesFile: ({fileName}: ReconciliationData): void => {
        console.log('monthly_ibmiicl_discripanciesFile', fileName);
        store.dispatch(monthlyActions.setDiscrepanciesFile(fileName));
    },

    ibmiicl_reconciliationCompleted: (data: ReconciliationData): void => {
        const fileName = data.fileName;
        console.log('ibmiicl_reconciliationCompleted', fileName);
        const token = store.getState().auth.token;
        fetch(`apiUrl/ibmiicl/${fileName}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Make sure to send authorization if needed
            },
        })
        .then(response => response.blob())
        .then(blob => {
            // Create a new URL for the blob object
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            // Cleanup: remove the anchor element and revoke the object URL
            a.remove();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading the file:', error));
    },
};



export type wsIncomingMessagesKeys = keyof typeof wsIncomingMessages;

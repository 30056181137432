// src/features/userSessionSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../hooks";
import type { MonthlyState, AddFileUploadedPayload, ProcessingStatus } from "./monthlyTypes";
import { useCallback } from "react";

const initialState: MonthlyState = {
  filesUploaded: {
    iBMi: null,
    ICL: null,
  },
  isUploading: false,
  status: "",
  statusHistory: [],
  discrepanciesFileName: "",
  progress: 0,
};

export const monthlySlice = createSlice({
  name: "monthly",
  initialState,
  reducers: {
    resetIbmiVsIcl: (_state) => {
      return initialState;
    },
    resetUploadedFiles: (state) => {
      state.filesUploaded = initialState.filesUploaded;
    },
    addUploadedFile: (state, action: PayloadAction<AddFileUploadedPayload>) => {
      const {fileType, fileName} = action.payload;
        state.filesUploaded[fileType] = fileName;

    },
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload;
    },
    setStatus: (state, action: PayloadAction<ProcessingStatus>) => {
      const {status, progress} = action.payload;
      if (status) {
        state.status = action.payload.status;
        state.statusHistory.push(action.payload.status);
      }
      if (progress) {
        state.progress = action.payload.progress;
      }
    },
    setDiscrepanciesFile: (state, action: PayloadAction<string>) => {
      state.discrepanciesFileName = action.payload;
    },
  },
});

export const useMonthly = () => {
  const dispatch = useAppDispatch();

  const filesUploaded = useAppSelector((state) => state.monthly.filesUploaded);
  const status = useAppSelector((state) => state.monthly.status);
  const progress = useAppSelector((state) => state.monthly.progress);
  const discrepanciesFileName = useAppSelector((state) => state.monthly.discrepanciesFileName);
  const isUploading = useAppSelector((state) => state.monthly.isUploading);

  const addUploadedFile = (data: AddFileUploadedPayload) => {
    dispatch(monthlySlice.actions.addUploadedFile(data));
  };

  const resetUploadedFiles = useCallback(() => {
    dispatch(monthlySlice.actions.resetUploadedFiles());
  }, [dispatch]);

  const resetIbmiVsIcl = useCallback(() => {
    dispatch(monthlySlice.actions.resetIbmiVsIcl());
  }, [dispatch]);

  const setIsUploading = useCallback((isUploading: boolean) => {
    dispatch(monthlySlice.actions.setIsUploading(isUploading));
  }, [dispatch]); 

  return { filesUploaded, status, progress, discrepanciesFileName, isUploading, resetUploadedFiles, resetIbmiVsIcl, addUploadedFile, setIsUploading };
};


export const monthlyActions = monthlySlice.actions;

export default monthlySlice.reducer;

export enum WsStatusEnum {
    idle = 'idle',
    connecting = 'connecting',
    connected = 'connected',
    reconnecting = 'reconnecting',
    disconnected = 'disconnected',
    error = 'error',
}
export interface WsState {
    status: WsStatusEnum;
    socketId: null | string;
    lastError: null | string;
}

// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';

import wsMiddleware from './middleware/wsMiddleware';

import wsReducer from './features/ws/wsSlice';
import authReducer from './features/auth/authSlice';
import monthlyReducer from './features/monthly/monthlySlice';

// Configuration object for redux-persist
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"], // Only auth will be persisted
};

const rootReducer = combineReducers({
    auth: authReducer,
    ws: wsReducer,
    monthly: monthlyReducer,
    // Add other slice reducers here
  });

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        const middlewares = [wsMiddleware];

        return getDefaultMiddleware({
            serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
          }).concat(middlewares);
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
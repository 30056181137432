// src/features/userSessionSlice.ts
import { useEffect } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { wsActions } from "../ws/wsSlice";
import type { AuthState, AuthPayload, TokenType } from "./authTypes";

const initialState: AuthState = {
  token: null,
  expiresAt: 0,
  userInfo: {},
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      console.log("autSlice: Logging out...");
      state.token = null;
      state.expiresAt = 0;
      state.userInfo = {};
      state.isAuthenticated = false;
    },
    setToken: (state, action: PayloadAction<AuthPayload>) => {
      state.token = action.payload.token;
      state.expiresAt = action.payload.expiresAt;
      state.userInfo = action.payload.userInfo;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
        if (state.isAuthenticated === action.payload) return;
        state.isAuthenticated = action.payload;
        // Clear token and user info if not authenticated
        if (!action.payload) {
            state.token = null;
            state.expiresAt = 0;
            state.userInfo = {};
        }
    },
  },
});


export const useTokenValidation = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const expiresAt = useAppSelector((state) => state.auth.expiresAt);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const interval = setInterval(() => {
        checkTokenValidity();
        
    }, 60000); // Runs every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [token, dispatch]);


  // Function to check if the token is still valid
  const checkTokenValidity = () => {
    // Should it (still) be authenticated?
    // check if a token exists and if it hasn't expired yet
    const nextIsAuthenticated = token !== null && new Date().getTime() < expiresAt * 1000;
    
    // If the authentication status has changed, update the store
    if (isAuthenticated !== nextIsAuthenticated) {
      console.log("Setting isAuthenticated to: ", nextIsAuthenticated);
      dispatch(authSlice.actions.setIsAuthenticated(nextIsAuthenticated));
      if (!nextIsAuthenticated) {
        dispatch(authSlice.actions.setToken({ token: null, expiresAt: 0, userInfo: {}}));
      }
    }
  };
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const logout = () => {
    console.log("useAuth: Logging out...");
    dispatch(authSlice.actions.logout());
  };

  const setToken = ({
    token = null,
    expiresAt = 0,
    userInfo = {},
  }: AuthState) => {
    dispatch(authSlice.actions.setToken({ token, expiresAt, userInfo }));
    dispatch(authSlice.actions.setIsAuthenticated(token !== null && new Date().getTime() < expiresAt * 1000));
  };

  return { isAuthenticated, token, setToken, userInfo, logout };
};

export const authActions = authSlice.actions;

export default authSlice.reducer;

import React, {
    useEffect,
    useRef,
    useState
  } from 'react';
  import { faCaretDown, faSignOutAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { useAuth } from '../store/features/auth/authSlice';
  import type { UserInfo } from '../store/features/auth/authTypes';
  import defaultAvatar from '../images/defaultAvatar.png';

  interface DropdownItemProps {
    item: {
      icon: IconDefinition;
      title: string;
      onClick: () => void;
    };
  }
  
  const DropdownItem: React.FC<DropdownItemProps> = ({ item }) => (
    <button
      className="text-gray-700 flex items-center"
      onClick={item.onClick}
    >
      <FontAwesomeIcon icon={item.icon} />
      <p className="ml-2">{item.title}</p>
    </button>
  );
  
  interface DropdownContentProps {
    dropdownItems: DropdownItemProps['item'][];
  }
  
  const DropdownContent: React.FC<DropdownContentProps> = ({ dropdownItems }) => (
    <div className="bg-white w-full absolute p-4 shadow-lg rounded-lg mt-2">
      {dropdownItems.map((item, i) => (
        <div className="mt-1" key={i}>
          <DropdownItem item={item} />
        </div>
      ))}
    </div>
  );
  
  const AvatarDropdown: React.FC = () => {
    const node = useRef<HTMLDivElement>(null);
    const { userInfo, logout } = useAuth() as { userInfo: UserInfo, logout: () => void };;
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const dropdownItems = [
      {
        title: 'Log Out',
        icon: faSignOutAlt,
        onClick: logout
      }
    ];
  
    const handleClick = (e: MouseEvent) => {
      if (node.current && !node.current.contains(e.target as Node)) {
        setDropdownOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClick);
  
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, []);
  
    return (
      <div ref={node}>
        <button
          className="flex rounded-full items-center py-2 px-3 bg-gradient focus:outline-none shadow-lg"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <img
            src={userInfo.avatar || defaultAvatar}
            className="rounded-full w-6 border-2 border-white"
            alt="Avatar"
          />
          <div className="px-3">
            <p className="text-white">{userInfo.firstName}</p>
          </div>
          <div className="mr-1 text-white">
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </button>
  
        {dropdownOpen && (
          <div className="relative">
            <DropdownContent dropdownItems={dropdownItems} />
          </div>
        )}
      </div>
    );
  };
  
  export default AvatarDropdown;
  
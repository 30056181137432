import {Middleware} from '@reduxjs/toolkit';
import {wsActions} from '../features/ws/wsSlice';
import { authActions } from '../features/auth/authSlice';
import {socket} from '../../utils/wsClient';

import connect from './wsMiddleareActions/connect';

const wsMiddleware: Middleware = store => next => action => {
    if (wsActions.connect.match(action)) {
        connect(store);
    }

    if (authActions.logout.match(action)) {
        console.log('logout **************************************************************************');   
        if (socket) {
            socket.disconnect();
        }
    }

    next(action);
};

export default wsMiddleware;

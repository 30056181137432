import React, { ReactNode } from 'react';
import GradientBar from './components/common/GradientBar';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';

interface AppShellProps {
  children: ReactNode;
}

const AppShell: React.FC<AppShellProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <GradientBar />
      <div className="flex flex-1">
        <div className="sm:w-64 px-4 sm:px-8 pt-6 bg-white">
          <Sidebar />
        </div>
        <div className="flex flex-col w-full">
          <div className="p-4 border-b border-gray-200 bg-white">
            <Navbar />
          </div>
          <div className="flex flex-col flex-1 overflow-auto">
            <div className="px-4 sm:px-8 py-2 bg-gray-100 flex-1">
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppShell;